import AWSAppSyncClient from 'aws-appsync';
import VueApollo from 'vue-apollo';
import {
  isTokenExpired,
  refreshAccessToken,
  getAccessToken,
} from '@/lib/auth';
import localForage from '@/lib/localForage';
import { getConfigEnv } from '@/utils/helpers';

const client = new AWSAppSyncClient({
  url: getConfigEnv('META_DATA_ENDPOINT_URL'),
  region: getConfigEnv('APPSYNC_REGION'),
  auth: {
    type: 'AMAZON_COGNITO_USER_POOLS',
    jwtToken: async () => {
      if (isTokenExpired()) {
        await refreshAccessToken();
      }
      return getAccessToken();
    },
  },
  disableOffline: true,
  offlineConfig: {
    storage: localForage,
    keyPrefix: 'metadata',
  },
}, {
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

const appSyncProvider = new VueApollo({
  defaultClient: client,
});
const metadataApolloClient = appSyncProvider.defaultClient;

export default metadataApolloClient;
